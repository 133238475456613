import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index/index.vue'
import Login from '../views/login/login.vue'

Vue.use(VueRouter)
const changePush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return changePush.call(this, location).catch(err => err);
}

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    // beforeEnter:(to,form,next) => {
    //   if(null != sessionStorage.getItem('token')){
    //     next()
    //   }else{
    //     window.location.href = 'http://124.239.133.66:15000/login?service=http://172.16.1.14:18082/login'
    //   }
    // },
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/shouye/home.vue')
      },
      {
        path: '/importDetection',
        name: 'importDetection',
        component: () => import('../views/shouye/importDetection.vue')
      },
      {
        path: '/projectUser',
        name: 'projectUser',
        component: () => import('../views/shouye/projectUser.vue')
      },
      {
        path: '/stationArea',
        name: 'stationArea',
        component: () => import('../views/shouye/stationArea.vue')
      },
      {
        path: '/stanmanageproject',
        name: 'stanmanageproject',
        component: () => import('../views/shouye/stanmanageproject.vue'),
      },
      {
        path: '/sectionManagement',
        name: 'sectionManagement',
        component: () => import('../views/shouye/sectionManagement.vue')
      },
      {
        path: '/stanmanageAll',
        name: 'stanmanage',
        component: () => import('../views/standard/stanmanage.vue'),
      },
      {
        path: '/project',
        name: 'project',
        component: () => import('../views/system/project.vue'),
      },
      {
        path: '/manageUser',
        name: 'manageUser',
        component: () => import('../views/system/manageUser.vue'),
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/system/user.vue'),
      },
    ]
  },
  {
    path: '/standard',
    name: 'standard',
    component: () => import('../views/standard/standard.vue'),
    children: [
      {
        path: '/requestType',
        name: 'requestType',
        component: () => import('../views/standard/requestType.vue'),
      },
      {
        path: '/speciality',
        name: 'speciality',
        component: () => import('../views/standard/speciality.vue'),
      },
      
      {
        path: '/requestName',
        name: 'requestName',
        component: () => import('../views/standard/requestName.vue'),
      }
    ]
  },
  {
    path: '/system',
    name: 'system',
    component: () => import('../views/system/system.vue'),
    children: [
      
      {
        path: '/Organ',
        name: 'Organ',
        component: () => import('../views/system/Organ.vue'),
      },
      {
        path: '/department',
        name: 'department',
        component: () => import('../views/system/department.vue'),
      },
      {
        path: '/identity',
        name: 'identity',
        component: () => import('../views/system/identity.vue'),
      },
      {
        path: '/region',
        name: 'region',
        component: () => import('../views/system/region.vue'),
      },
      {
        path: '/permission',
        name: 'permission',
        component: () => import('../views/system/permission.vue'),
      },
      
      {
        path: '/setting',
        name: 'setting',
        component: () => import('../views/system/setting.vue'),
      }
    ]
  }

,
{
  path: '/login',
    name: 'login',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: Login
},
{
  path: '/indexs',
    name: 'indexs',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/indexs/indexs.vue'),
  },
{
  path: '/noAuth',
    name: 'nopermission',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/nopermission/nopermission.vue'),
  }

]

const router = new VueRouter({
  routes
})

export default router
